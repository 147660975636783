<template>
	<div>
		<div class="split-bg">
			<div style="width: 41%;height: 100%; background-color: #CCE3FD "></div>
		</div>
		<div class="vertical-align-wrap" style="z-index: 2">
			<div class="vertical-align-top" style="z-index: 2;height: 100%">
				<div class="auth-box">
					<div class="left">
						<div class="content">
							<div class="logo text-center">
								<a href>
									<img height="90" :src="logo" alt="Boscod Logo" />
								</a>
							</div>
							<div class="header">
								<p class="lead"></p>
							</div>

                            <!-- Form Login -->
							<form v-if="isLoginView" ref="formSubmit" class="form-auth-small" action @submit="submitLogin" method="post">
								<div class="alert alert-danger" v-if="failed">{{ failedMsg }}</div>
								<div class="form-group">
									<label for="email" class="control-label float-left" style="color:#62676F;">Email</label>
									<input type="email" class="form-control" id="email" autocomplete="off" autocorrect="off" autocapitalize="off" spellcheck="false" 
										:class="{ 'is-invalid': $v.form.username.$error }" required name="login"
										@input="setUser($event.target.value)"  v-on:keyup.enter="prosesSubmit" 
										placeholder="Masukkan Email" v-model="form.username" 
										onkeydown="return (event.ctrlKey || event.altKey 
										|| (47<event.keyCode && event.keyCode<58 && event.shiftKey==false) || (event.keyCode==50 && event.shiftKey==true)
										|| (95<event.keyCode && event.keyCode<106)|| (event.keyCode==8) || (event.keyCode==9) || (event.keyCode==190 && event.shiftKey==false) || (event.keyCode==189 && event.shiftKey==false) || (event.keyCode==189 && event.shiftKey==true)
										|| (34<event.keyCode && event.keyCode<40) || (event.keyCode==32) || (event.keyCode==46) || (event.keyCode==13) || (64<event.keyCode && event.keyCode<91))" onkeyup="return console.log(event.keyCode )"/>
								</div>
								<div class="form-group">
									<label for="signin-password" class="control-label float-left" style="color:#62676F;">Password</label>
									<div class="input-group">
                                        <input :type="showPassword?'text':'password'" name="password" id="signin-password" class="form-control" :class="{ 'is-invalid': $v.form.password.$error }" @input="setPass($event.target.value)"  v-on:keyup.enter="prosesSubmit" autocomplete="off" placeholder="Masukkan Password" v-model="form.password" />
                                        <div class="input-group-append" style="cursor: pointer;">
                                            <div class="input-group-text" v-on:click="toggleShowPassword">
                                                <span v-show="!showPassword"><span class="fas fa-eye-slash"></span></span>
                                                <span v-show="isShowPassword"><span class="fas fa-eye"></span></span>
                                            </div>
                                        </div>
									</div>
								</div>
								<div class="form-group clearfix">
									<label class="fancy-checkbox element-left">
										<input type="checkbox" name="remember" v-model="form.remember" />
										<span>Selalu ingat</span>
									</label>
									<label class="element-right">
										<span style="color: #FF7203; cursor: pointer;" v-on:click="showForgot">Lupa Password?</span>
									</label>
								</div>
								<button type="button" @click="prosesSubmit" class="btn btn-primary btn-lg btn-block" style="border-radius: 4.8px; height: 42px; padding: 0px;">
									<span v-if="loading" class="spinner-border spinner-border-sm" role="status"
										aria-hidden="true"></span>
									{{ loading?" Login...":"Login" }}
									<!-- <i class="fa fa-sign-in-alt"></i> -->
								</button>
								<div class="bottom">
                                    <div class="separator" style="color: #959595;">&nbsp;atau&nbsp;</div>
									<br />
                                    <button type="button" @click="loginWithGoogle" class="btn btn-outline-primary btn-lg btn-block googlebtn" style="border-radius: 4.8px; height: 42px; padding: 0px;">
                                        <img src="@/assets/img/google.svg" width="4.5%" alt="google icon"> &nbsp;Lanjutkan dengan Google
									    <!-- <i class="fa fa-sign-in-alt"></i> -->
								    </button>
								</div>
							</form>

                            <!-- Form Forgot Password -->
							<form v-if="!isLoginView" class="form-auth-small" action @submit="submitForgot"
								method="post">
								<div :class="resetClass" v-if="resetAction">{{ message }}</div>
								<div class="form-group">
									<label for="signin-email" class="control-label sr-only">Email</label>
									<input type="email" class="form-control" name="login" id="signin-email" required
										placeholder="Email" v-model="email" />

									<input name="log-me-in" type="hidden" value="1" />
								</div>

								<button type="submit" class="btn btn-primary btn-lg btn-block">
									<span v-if="loading" class="spinner-border spinner-border-sm" role="status"
										aria-hidden="true"></span>
									{{ loading?" Reset Password...":"Reset Password" }}
									<!-- <i class="fa fa-refresh"></i> -->
								</button>
								<div class="bottom">
                                    <span style="color: #9699A0;">
                                        Sudah punya akun? &nbsp;<b style="color: #2F4858; cursor: pointer;" v-on:click="showLogin">Login</b>
									</span>
								</div>
							</form>
						</div>
					</div>
					<div class="right">
						<!-- <div class="overlay"></div> -->
						<div id="demo" class="carousel slide carousel-fade" data-ride="carousel" data-interval="false"
							data-pause="hover">
							<!-- Indicators -->
							<ul class="carousel-indicators">
								<li data-target="#demo" data-slide-to="0" class="active"></li>
								<li data-target="#demo" data-slide-to="1"></li>
								<li data-target="#demo" data-slide-to="2"></li>
							</ul>
							<p style="font-size: 8pt;color: #9e9e9e;position: absolute;bottom: 100px;left: 0;right: 0;" class="d-block text-center" v-if="false">
								Illustration from <img src="@/assets/img/logo-footer.svg" alt="storyset. by Freepik" height="12px" class="d-inline" />
							</p>

							<!-- The slideshow -->
							<div class="carousel-inner">
								<div class="carousel-item active">
									<img src="@/assets/img/Slider1.svg" width="50%" class="d-block w-100-1" alt="Slide 1" />
									<div class="carousel-caption d-none d-md-block">
										<h5 style="color: #707070;text-decoration: none;font-size: 16pt;">BOSCOD
											Platform Fokus Sukses COD</h5>
										<p style="font-size: 12pt;color: #9e9e9e">Monitoring, Analisis, and Payment.
											Super Integrasi platform untuk memudahkan Anda bisa sukses COD.</p>
									</div>
								</div>
								<div class="carousel-item">
									<img src="@/assets/img/Slider2.svg" width="50%" class="d-block w-100-1" alt="Slide 2" />
									<div class="carousel-caption d-none d-md-block">
										<h5 style="color: #707070;text-decoration: none;font-size: 16pt;">Diskon Ongkir
											Istimewa</h5>
										<p style="font-size: 12pt;color: #9e9e9e">Kami berusaha membuat Anda lebih
											diuntungkan menggunakan platform kami dibandingkan yang lainnya.</p>
									</div>
								</div>
								<div class="carousel-item">
									<img src="@/assets/img/Slider3.svg" width="50%" class="d-block w-100-1" alt="Slide 3" />
									<div class="carousel-caption d-none d-md-block">
										<h5 style="color: #707070;text-decoration: none;font-size: 16pt;">Tidak Ada
											Retur Tanpa Konfirmasi</h5>
										<p style="font-size: 12pt;color: #9e9e9e">Kami memastikan dengan sistem kami,
											tidak ada retur yang tidak dikonfirmasi ke Anda.</p>
									</div>
								</div>
							</div>

							<!-- Left and right controls -->
							<a class="carousel-control-prev" href="#demo" data-slide="prev">
								<span class="carousel-control-prev-icon"></span>
							</a>
							<a class="carousel-control-next" href="#demo" data-slide="next">
								<span class="carousel-control-next-icon"></span>
							</a>
						</div>
						<div class="clearfix"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Vue, { onMounted, ref, watch } from 'vue'

import { auth } from "@/libs/hxcore";
import $ from "jquery";
import { required, minLength, between } from "vuelidate/lib/validators";
import googleOneTapSignin from '../libs/googleOneTapSignin' 

//import '@/assets/login.css';
export default {
	name: "Login",
	data() {
		return {
			errors: [],
			loading: false,
			failed: false,
			failedMsg: "",
			message: "",
			isLoginView: true,
			resetAction: false,
			resetClass: "",
			email: "",
			form: {
				username: "",
				password: "",
				remember: false
			},
			logo: '',
            showPassword: false,
		};
	},
	
    computed: {
        isShowPassword: function () {
            return this.showPassword;
        },
    },
	created: function(){
		if(Vue.config.appHost == 'xpress')
			this.logo = require(`../assets/img/logo-xpress.png`);
		else this.logo = require(`../assets/img/logo-min.svg`);
		//this.initGooglePopup();
		this.googleTap()
	},
	validations: {
		form: {
			username: {
				required
			},
			password: {
				required
			}
		}
	},
	methods: {
		googleTap(){			
			const self = this;
			const { googleOptions, oneTapSignin, userData } = googleOneTapSignin()
			oneTapSignin(googleOptions, function(js){
				if (js.status) {
					auth.login(js);
					self.$router.push("/");
				} else {
					if(js.rc=='01')
					{
						self.$router.push({name:"Registrasi", params:{user: js.user}});
					}
					else{
						self.failed = true;
						self.failedMsg = js.message;
					}
				}
			})
		},
        toggleShowPassword: function(e)
        {
            // console.log( e.target);
            this.showPassword = !this.showPassword;
        },
		setUser(value) {
			//this.username = value;
			this.$v.form.username.$touch();
		},
		setPass(value) {
			//this.password = value;
			this.$v.form.password.$touch();
		},
		prosesSubmit: function()
		{
			this.$v.$touch();
			if (this.$v.$invalid) {
				return;
			}
			if(!this.$refs.formSubmit.checkValidity())
				return;
			this.submitLogin();
		},
		submitLogin: function (e) {	
			console.log('submit')	
			if (this.form.username && this.form.password) {
				var data = Object.keys(this.form)
					.map(
						key =>
						encodeURIComponent(key) + "=" + encodeURIComponent(this.form[key])
					)
					.join("&");
				this.loading = true;
				this.failed = false;
				fetch(window.$apiUrl + "/login", {
						method: "POST",
						headers: {
							"Content-Type": "application/x-www-form-urlencoded"
						},
						body: data
					})
					.then(res => {
						this.loading = false;
						if (res.status === 201) {} else if (res.status === 400) {
							//let errorResponse = await res.json();
							//this.errors.push(errorResponse.error);
						}
						return res.json();
					})
					.then(js => {
						if (js.status) {
							auth.login(js);
							this.$router.push("/");
						} else {
							this.failed = true;
							this.failedMsg = js.message;
						}
					});
			}
			return false;
			//e.preventDefault();
		},
		submitForgot: function (e) {
			if (this.email) {
				var data = "email=" + this.email;
				this.loading = true;
				this.failed = false;
				fetch(window.$apiUrl + "/login/forgot", {
						method: "POST",
						headers: {
							"Content-Type": "application/x-www-form-urlencoded"
						},
						body: data
					})
					.then(res => {
						this.loading = false;
						if (res.status === 201) {} else if (res.status === 400) {
							//let errorResponse = await res.json();
							//this.errors.push(errorResponse.error);
						}
						return res.json();
					})
					.then(js => {
						if (js.status) {
							this.message = js.message;
							this.resetClass = "alert alert-success";
							this.email = "";
						} else {
							this.message = js.message;
							this.resetClass = "alert alert-danger";
						}
						this.resetAction = true;
					});
			}

			e.preventDefault();
		},
		loginWithGoogle() {
			const self = this;
			this.$gAuth
				.signIn()
				.then(GoogleUser => {
					fetch(window.$apiUrl + '/users/login/google_login', {
						method: "POST",
						headers: {
							Accept: "application/json",
							"Content-Type": "application/json",
						},
						body: JSON.stringify(GoogleUser)
					})
					.then(res => {
						if (res.status === 201) {} else if (res.status === 400) {
							//let errorResponse = await res.json();
							//this.errors.push(errorResponse.error);
						}
						return res.json();
					})
					.then(js => {
						if (js.status) {
							auth.login(js);
							self.$router.push("/");
						} else {
							if(js.rc=='01')
							{
								self.$router.push({path:"/register", params:{user: js.user}});
							}
							else{
								this.failed = true;
								this.failedMsg = js.message;
							}
						}
					})
				})
				.catch(error => {
					console.log("error", error);
				});
		},
		

		showForgot: function () {
			this.isLoginView = false;
		},

		showLogin: function () {
			this.isLoginView = true;
			this.resetAction = false;
		}
	}
};
$(function () {
	// $('[data-toggle="password"]').each(function () {
	// 	var input = $(this);
	// 	var eye_btn = $(this)
	// 		.parent()
	// 		.find(".input-group-text");
	// 	eye_btn.css("cursor", "pointer").addClass("input-password-hide");
	// 	eye_btn.on("click", function () {
	// 		if (eye_btn.hasClass("input-password-hide")) {
	// 			eye_btn
	// 				.removeClass("input-password-hide")
	// 				.addClass("input-password-show");
	// 			eye_btn
	// 				.find(".fas")
	// 				.removeClass("fa-eye")
	// 				.addClass("fa-eye-slash");
	// 			input.attr("type", "text");
	// 		} else {
	// 			eye_btn
	// 				.removeClass("input-password-show")
	// 				.addClass("input-password-hide");
	// 			eye_btn
	// 				.find(".fas")
	// 				.removeClass("fa-eye-slash")
	// 				.addClass("fa-eye");
	// 			input.attr("type", "password");
	// 		}
	// 	});
	// });
	setInterval(function () {
		$('[data-slide="next"]').click();
	}, 5000);
});
</script>
<style scoped src="../assets/login.css"></style>
<style scoped>
.widget-block {
	height: 100%;
}

.split-bg {
	position: absolute;
	width: 100%;
	height: 100%;
	display: none
		/*table*/
	;
	background-color: #21316f;
	/*background-image: url(../../assets/img/repeat-bg.jpg);*/
	background-repeat: repeat repeat;
	z-index: 1;
}

.w-100-1 {
	/*width: 70%!important;
      height: 50%;*/
	/*height: -webkit-fill-available;*/
}

.carousel-indicators .active {
	height: 11px;
	width: 44px;
    background-color: #FF7203;
    border-radius: 100px;
}

.vertical-align-wrap {
	background-color: transparent;
	background-image: none;
}

.carousel-item-next,
.carousel-item-prev,
.carousel-item.active {
	height: 100%;
}

.auth-box {
	border-radius: 15px;
	width: 100%;
	height: 100vh;
	/*height: 600px*/
}

.carousel-indicators li {
	border-top: none;
	border-bottom: none;
	height: 11px;
    width: 15px;
	display: inline-block;
	background-color: #FFCCA3;
    border-radius: 100px;
}

.carousel-inner {
	height: 100%;
	/*border-radius: 0px 15px 15px 0px;*/
}

.carousel-indicators {
	display: block;
	text-align: center;
	width: 70%;
}

.carousel-caption {
	bottom: auto;
	margin-top: -30px;
	vertical-align: middle;
}

.right-side {
	float: right;
	width: 60%;
	height: 100%;
	position: relative;
}

.auth-box .right {
	float: right;
	width: 60%;
	height: 100%;
	position: relative;
	background: none;
	border-radius: 15px;
	/*background-image: url(../../assets/img/login-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;*/
}

.auth-box .right:before {
	display: contents;
}

.auth-box .left {
	float: left;
	width: 40%;
	height: 100%;
	padding: 0 60px;
	text-align: center;
}

@media screen and (max-width: 1023px) {
	.auth-box .left {
		width: 100%;
		position: inherit;
		padding: 20px;
	}

	.split-bg {
		display: none;
	}

	.vertical-align-wrap {
		/*background-color: #21316F;*/
		/*background-image: url(../../assets/img/repeat-bg.jpg);*/
		background-repeat: repeat repeat;
		z-index: 1;
	}
}

@media screen and (max-width: 1279px) {
	.auth-box .left {
		top: 3.3em;
	}

	.split-bg {
		display: none;
	}

	.vertical-align-wrap {
		/*background-color: #21316F;*/
		/*background-image: url(../../assets/img/repeat-bg.jpg);*/
		background-repeat: repeat repeat;
		z-index: 1;
	}
}

.carousel {
	height: 100%;
	background-color: #FFF1E6;
	/* background: linear-gradient(261deg,
			rgba(237, 237, 237, 0) 44%,
			rgba(31, 174, 203, 0.23713235294117652) 100%); */
}

.carousel-inner img {
	/*min-height: 100%;*/
	background-size: cover;
	/*height: -webkit-fill-available;*/
	margin-top: 30px;
	margin-left: auto;
	margin-right: auto;
}

/********************************/
/*            Overlay           */
/********************************/
.overlay {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 2;
	background-color: #080d15;
	opacity: 0.5;
}

body {
	color: #676a6d;
}

.form-group {
	margin-bottom: 15px;
}

.form-control {
	height: 34px;
	padding: 6px 12px;
}

.btn-lg {
	padding: 10px 28px;
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #DDDDDD;
}

.separator:not(:empty)::before {
  margin-right: .25em;
}

.separator:not(:empty)::after {
  margin-left: .25em;
}

.googlebtn{
    color: #FF7203;
    border: 2px solid #FF7203;
    background-color: white;
    border-radius: 4px;
}

.googlebtn:hover{
    color: #944100;
    border: 2px solid #944100;
    background-color: white;
    border-radius: 4px;
}
</style>
